.bnbb {
  height: 19% !important;
  width: 100% !important;
  min-width: 100% !important;
  position: absolute !important;
  z-index: 1 !important;
  top: 0px !important;
  left: 0px !important;
  background: linear-gradient(180deg, #000000b5 1.61%, rgb(0 0 0 / 0%) 92.19%) !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
  pointer-events: none !important;
}

.bnbb.lgs {
  background-color: #000 !important;
  height: 100% !important;
  opacity: 0.3 !important;
}
